import BaseEntity from '@/entity/BaseEntity';
import { SUITE_URL } from '@/utils/CONST';
import { formatPhone, formatSiret } from '@/utils/tools';
import { itemIs } from '@orion.ui/orion';

export default class OrganizationCompanyListItemEntity<
  T extends Armado.CompanyOrganizationItemModel = Armado.CompanyOrganizationItemModel
> extends BaseEntity<T> {

	get name () { return this.state.name; }
	get siret () { return this.state.contactDetailSiret?.length ? this.state.contactDetailSiret : 'Non renseigné'; }
	get phone () { return this.state.contactDetailHomePhone?.length ? this.state.contactDetailHomePhone : 'Non renseigné'; }
	get email () { return this.state.contactDetailEmail?.length ? this.state.contactDetailEmail : 'Non renseigné'; }
	get location () {return [this.state.contactDetailCity, this.state.contactDetailPostCode].filterNil().join(' - ');}
	get siretFormatted () { return formatSiret(this.siret); }

	get suiteCompanyId () {
		if (itemIs<ICompanyForAgencyOrganizationItemModel>(this.state, 'suiteCompanyId'))
			return this.state.suiteCompanyId;
		else if (itemIs<ICompanyForClientOrganizationItemModel>(this.state, 'agencyCompanyEnsoSuiteCompanyId'))
			return this.state.agencyCompanyEnsoSuiteCompanyId;
	}

	get formattedPhone () {
		return this.state.contactDetailHomePhone?.length ? formatPhone(this.state.contactDetailHomePhone) : 'Non renseigné';
	}

	get logoId () {
		return itemIs<ICompanyForAgencyOrganizationItemModel>(this.state, 'companyLogoId')
			? this.state.companyLogoId
			: undefined;
	}

	get logoUrl () {
		return this.suiteCompanyId
			? `${SUITE_URL}/app/company/${this.suiteCompanyId}/logo`
			: this.state.id;
	}

	get agencyCompanyId () {
		return itemIs<ICompanyForClientOrganizationItemModel>(this.state, 'agencyCompanyId')
			? this.state.agencyCompanyId
			: undefined;
	}

	get agencyCompanyName () {
		return itemIs<ICompanyForClientOrganizationItemModel>(this.state, 'agencyCompanyName')
			? this.state.agencyCompanyName
			: undefined;
	}

	get agencyCompanyCity () {
		return itemIs<ICompanyForClientOrganizationItemModel>(this.state, 'agencyCompanyContactDetailCity')
			? this.state.agencyCompanyContactDetailCity
			: undefined;
	}


	setLogo (logoId: number) {
		(this.state as ICompanyForAgencyOrganizationItemModel).companyLogoId = logoId;
	}

	deleteLogo () {
		(this.state as ICompanyForAgencyOrganizationItemModel).companyLogoId = undefined;
	}
}
