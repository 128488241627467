/* eslint-disable no-unused-vars */
const DEV_URL = 'https://localhost:5001'; // local
//const DEV_URL = 'http://10.0.10.54:5000'; // VM Mobile
const isOnline = import.meta.env.VITE_NODE_ENV !== 'development';

const ARMADO_URL = isOnline ? location.origin : DEV_URL;

const DOCUMENT_EXTENSION = {
	'application/zip': '.zip',
	'application/pdf': '.pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
};

const ONBOARDING = {
	Order: 'tourOrder',
	OrderDetails: 'tourOrderDetails',
	JobOffer: 'tourJobOffer',
};

const HELP = {
	List: 0,
	Signature: 1,
	Command: 2,
	RecordHour: 4,
	ContractProlongation: 8,
	DigitalSafe: 16,
	Role: 32,
	Tag: 64,
};

const SUITE_URL = import.meta.env.VITE_SUITE_URL;

export { ARMADO_URL, isOnline, DOCUMENT_EXTENSION, ONBOARDING, HELP, SUITE_URL };
