import { ARMADO_URL } from '@/utils/CONST';

interface IGetAuthRedirectionLinkModel {
	redirect?: string
	prompt?: string
	login_hint?: string
}

class OAuthApi {

	static redirectToIdp (payload: IGetAuthRedirectionLinkModel) {
		const query = new URLSearchParams(payload as unknown as Record<string, string>);
		const url = new URL(
			`${ARMADO_URL}/app/auth/redirect-to-idp`,
		);
		url.search = query.toString();

		window.location.href = url.href;
	}
}

export default OAuthApi;
