<template>
	<o-avatar
		root-url="/app/companylogo/"
		tooltip="Modifier le logo"
		:name="company.name"
		:avatar="isClientCompany ? undefined : setup.logoUrl"
		:nb-avatar-updates="setup.ui.token"
		:update-function="showLogoUpdate && setup.self.isAgency() && setup.self.can('CompanyLogo')
			? setup.updateLogoAsync.bind(setup)
			: undefined"
		size="sm"
		square
		contain/>
</template>

<script setup lang="ts">
import SharedLogoCompanySetupService from '@/setup/shared/SharedLogoCompanySetupService';
const props = defineProps(SharedLogoCompanySetupService.props);
const setup = new SharedLogoCompanySetupService(props);
</script>

<style scoped lang="less">
.orion-avatar {
  border-radius: 0.5rem;
}

:deep(.orion-avatar__picto) {
  transform: translate(0.5rem, 0.5rem);
}
</style>
