/* eslint-disable no-console */
import router from '@/router/index.router';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useLoader, useNotif } from '@orion.ui/orion';
import useNavLoaderService from '@/services/NavLoaderService';
import OAuthApi from '../oauth/OAuthApi';

const isFirefox = /Firefox/.test(window.navigator.userAgent);
let errorNotifInstance: Undef<OrionNotif>;

const loggerRequestInterceptor = function (config: InternalAxiosRequestConfig) {
	if (!isFirefox) {
		console.groupCollapsed(
			`%cTry ${config.method?.toUpperCase()} ${config.url}`,
			'color: white; font-size:10px; background: #198CFF; font-weight: 400; padding : 5px;',
		);
		console.log(config);
		console.groupEnd();
	}

	return config;
};

const loggerResponseInterceptor = function (response: AxiosResponse) {
	const url = response.config.url?.replace(response.config.baseURL ?? '', '');

	if (!isFirefox) {
		console.groupCollapsed(
			`%cSuccess ${response.config.method?.toUpperCase()} ${response.status} ${url}`,
			'color: white; font-size:10px; background: #00d883; font-weight: 400; padding : 5px;',
		);
		console.log(response?.data);
		console.groupEnd();
	}

	return response;
};

const loggerResponseErrorInterceptor = function (error: AxiosError) {
	if (!error.response) return Promise.reject(error);

	const url = error.response.config.url?.replace(error.response.config.baseURL ?? '', '');

	if (!isFirefox) {
		console.groupCollapsed(
			`%cError ${error.response.status} ${url} ${error.response.statusText}`,
			'color: white; font-size:10px; background: #ff0033; font-weight: 400; padding : 5px;',
		);
		console.log(error.response?.data);
		console.groupEnd();
	}

	return Promise.reject(error);
};

// une fois que cette variable est fixée à true, la page doit être rechargée pour revenir à false.
let unauthorized = false;

const responseErrorInterceptorAsync = async function (error: AxiosError) {
	if (!error.response) return Promise.reject(error);

	// console.log(`error.response.status`, error.response.status);
	const url = error.response.config.url?.replace(error.response.config.baseURL ?? '', '') ?? '';
	// console.log(`🚀 ~ responseErrorInterceptorAsync ~ url:`, url);
	const urlIsRefreshToken = url.indexOf('refreshtoken') !== -1;
	// console.log(`🚀 ~ responseErrorInterceptorAsync ~ urlIsRefreshToken:`, urlIsRefreshToken);
	// const urlIsSignin = /^auth\/signin$/.test(url);
	// console.log(`🚀 ~ responseErrorInterceptorAsync ~ urlIsSignin:`, urlIsSignin);
	const urlIsFingerprint = /^userfingerprint\/getPhoneNumber\//.test(url);
	// console.log(`🚀 ~ responseErrorInterceptorAsync ~ urlIsFingerprint:`, urlIsFingerprint);

	if (error.response.status === 401 && !urlIsRefreshToken) {
		const SessionManagerImport = () => import('@/utils/SessionManager');
		const SessionManager = await SessionManagerImport();
		const result = await SessionManager.default?.getTokenAsync();
		const routeName = router.currentRoute.value.name?.toString() ?? '';

		if (result) {
			return axios.request(error.response.config);
		} else {
			useLoader().hide();

			if (unauthorized) {
				return;
			}

			unauthorized = true;

			localStorage.removeItem('userType');

			if (['Login', 'Signup'].includes(routeName)) return;

			// userType agence par défaut (les autres types d'utilisateurs sont définis après une connexion réussie)
			localStorage.setItem('userType', '1');

			OAuthApi.redirectToIdp({
				redirect: window.location.href.toString(),
				prompt: 'none',
			});
			return;
		}
	} else if (
		(error.response?.data as any)?.code !== 'discussion_missing'
		&& !(window.location.pathname.startsWith('/signup') && error.response.status === 401)
	) {
		useLoader().hide();
		useNavLoaderService().hide();

		if (error.response.status === 422) {
			errorNotifInstance?.close();
			if (urlIsFingerprint) {
				errorNotifInstance = useNotif.info(`L'authentification à deux facteurs est nécessaire.`);
			} else if (typeof error?.response?.data === 'string') {
				errorNotifInstance = useNotif.danger(error.response.data);
			} else if (typeof (error?.response?.data as any)?.message === 'string') {
				errorNotifInstance = useNotif.danger((error.response.data as any)!.message);
			}
		} else {
			errorNotifInstance?.close();
			errorNotifInstance = useNotif.danger(
				`Oops...`,
				typeof error?.response?.data === 'string'
					? error?.response?.data
					: `Une erreur est survenue, merci de réessayer plus tard`,
			);
		}
	}

	return Promise.reject(error);
};

export {
	loggerRequestInterceptor,
	loggerResponseInterceptor,
	loggerResponseErrorInterceptor,
	responseErrorInterceptorAsync,
};
