import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('refreshtoken');

class RefreshTokenApi {
	static logout () {
		window.location.href = `${ARMADO_URL}/app/refreshtoken/logout`;
	}

	static refreshToken () {
		return client.get<ITokenModel>('refreshtoken');
	}
}

export default RefreshTokenApi;
