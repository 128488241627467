import { reactive } from 'vue';
import { Bus, useModal, useNotif } from '@orion.ui/orion';
import { delayRequestAsync } from '@/utils/delay-ajax';
import AjaxCooler from '@/utils/AjaxCooler';
import CompanyApi from '@/api/CompanyApi';
import MembershipApi from '@/api/MembershipApi';
import CompanyCandidateCodeApi from '@/api/CompanyCandidateCodeApi';
import useSelfService from './SelfService';
import OnboardingCompanyCodeModal from '@/components/onboarding/OnboardingCompanyCodeModal.vue';
import OnboardingTempWorkerAddMembershipModal from '@/components/onboarding/OnboardingTempWorkerAddMembershipModal.vue';
import OnboardingTempWorkerNoMembershipModal from '@/components/onboarding/OnboardingTempWorkerNoMembershipModal.vue';
import OnboardingTempWorkerPreventAddMembershipModal from '@/components/onboarding/OnboardingTempWorkerPreventAddMembershipModal.vue';

class MembershipService {
	private cooler = new AjaxCooler(CompanyApi.get);
	private readonly state = reactive({ relatedAgencies: [] as ICompanyDetailedModel[] });

	private get self () { return useSelfService().self; }

	get relatedAgencies () { return this.state.relatedAgencies; }

	async listAsync (forceRefresh = false, params: OmitUserId<ICompanyListByUserIdParameters>) {
		return await this.cooler.coolAsync(forceRefresh, params);
	}

	async addAsync (params: IAddMembershipModel) {
		try {
			const { data } = await MembershipApi.add(params);
			return data;
		} catch (e: any) {
			useNotif.danger(e?.response?.data);
		}
	}

	async deleteAsync (membershipId: number) {
		if (!this.self.organizationGuard()) return;

		try {
			await MembershipApi.delete(membershipId, this.self.organization.id);
		} catch (e: any) {
			useNotif.danger(e?.response?.data);
		}
	}

	async openTempWorkerAddMembershipModalAsync (code?: string, resolver?: () => void) {
		return new Promise<void>((resolve) => {
			useModal({
				Nested: OnboardingTempWorkerAddMembershipModal,
				NestedProps: { code },
				size: 'sm',
				events: {
					'confirm': async (modal, payload: {id: number, name: undefined | string}) => {
						await this.confirmAddMembershipAsync(payload.id, payload.name);
						modal.close({ flush: true });
						(resolver ?? resolve)();
					},
				},
			});
		});
	};

	async confirmAddMembershipAsync (id: number, agencyName: undefined | string) {
		try {
			await MembershipApi.addForCandidate(id);
			useNotif.success('Sauvegarde effectuée', `La lisaison avec l'agence ${agencyName} a bien été effectuée`);
			Bus.emit('DashboardTempWorkerSetupService:confirm-add-membership');
		} catch (error: any) {
			useNotif.danger('Oops', error.response.data);
		}
	};

	openTempWorkerPreventAddMembershipModal () {
		useModal({
			Nested: OnboardingTempWorkerPreventAddMembershipModal,
			size: 'sm',
		});
	}

	async openTempWorkerNoMembershipModalAsync () {
		const reminder = localStorage.getItem('remind-no-membership-modal');

		if (reminder === 'never') {
			return;
		} else if (reminder && /\d+/.test(reminder)) {
			const reminderDate = new Date(+reminder);
			if (reminderDate.valueOf() > new Date().valueOf()) return;
		}

		return new Promise<void>((resolve) => {
			useModal({
				Nested: OnboardingTempWorkerNoMembershipModal,
				size: 'sm',
				hideClose: true,
				hideOnOverlayClick: false,
				events: {
					'enter-code': () => this.openTempWorkerAddMembershipModalAsync(undefined, resolve),
					'remind-later': (modal) => {
						const remindDate = new Date();
						// Set the reminder to next day
						remindDate.setDate(remindDate.getDate() + 1);
						localStorage.setItem('remind-no-membership-modal', remindDate.valueOf().toString());
						modal.close();
						resolve();
					},
				},
			});
		});
	}
	// #endregion

	// #region Agency Modal
	async openCompanyCodeForMemberShipShareModalAsync (id: number) {
		return new Promise<void>((resolve) => {
			useModal({
				Nested: OnboardingCompanyCodeModal,
				NestedProps: { id },
				size: 'lg',
				events: {
					'shared': async (Modal, payload: any) => {
						await this.shareCodeToTempWorkerAsync(payload, id);
						// Modal.close();
						resolve();
					},
				},
			});
		});
	}
	// #endregion

	async getCompanyByCodeAsync (code: string) {
		const { data } = await delayRequestAsync(CompanyApi.getByCode(code), 2000);
		return data;
	}

	async getCodeCompanyByCompanyIdAsync (id: number) {
		const { data } = await CompanyCandidateCodeApi.selectByCompanyId(id);
		return data;
	};

	async shareCodeToTempWorkerAsync (payload: any, companyId: number) {
		try {
			if (payload.selectedShareMethod === 'phone') {
				await CompanyCandidateCodeApi.shareBySms(payload.phone.phoneNumber, payload.phone.phoneCountryCode, companyId);
			} else {
				await CompanyCandidateCodeApi.shareByEmail(payload.email, companyId);
			}
			useNotif.success('Partage réussi avec succès', `Le partage de votre code a bien été envoyé`);
		} catch (error: any) {
			useNotif.danger('Oops', error.response.data);
		}
	}

	async getRelatedAgenciesAsync () {
		const res = await CompanyApi.getDetailed();
		if (res?.data) {
			this.state.relatedAgencies = res.data;
		}
		return this.state.relatedAgencies;
	}
}

const membershipServiceSingleton = new MembershipService();

export default function useMembershipService () {
	return membershipServiceSingleton;
}
